import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex gap-x-4 flex-row flex-nowrap mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_delivery_status_card = _resolveComponent("delivery-status-card")!
  const _component_delivery_detail_card = _resolveComponent("delivery-detail-card")!
  const _component_delivery_distribution_per_product_card = _resolveComponent("delivery-distribution-per-product-card")!
  const _component_delivery_per_route_card = _resolveComponent("delivery-per-route-card")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Title, { label: _ctx.title }, null, 8, ["label"]),
    _createVNode(_component_delivery_status_card),
    _createVNode("div", _hoisted_1, [
      (!_ctx.isSenderAccount)
        ? (_openBlock(), _createBlock(_component_delivery_detail_card, {
            key: 0,
            class: "w-5/12"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_delivery_distribution_per_product_card, { class: "w-4/12" }),
      (!_ctx.isSenderAccount)
        ? (_openBlock(), _createBlock(_component_delivery_per_route_card, {
            key: 1,
            class: "w-3/12"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}