import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row mt-3" }
const _hoisted_2 = { class: "grid grid-cols-4 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailStatusCardDashboardClient = _resolveComponent("DetailStatusCardDashboardClient")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_CardDashboardClient = _resolveComponent("CardDashboardClient")!

  return (_openBlock(), _createBlock(_component_CardDashboardClient, {
    class: "my-4",
    title: "Status"
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_DetailStatusCardDashboardClient, {
          class: "w-48 pr-4",
          title: _ctx.totalStatusDelivery.title,
          tooltipTitle: _ctx.totalStatusDelivery.tooltip,
          loading: _ctx.totalStatusDelivery.loading,
          value: _ctx.totalStatusDelivery.value
        }, null, 8, ["title", "tooltipTitle", "loading", "value"]),
        _createVNode("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.deliveryPerStatus, (statusDelivery) => {
            return (_openBlock(), _createBlock(_component_DetailStatusCardDashboardClient, {
              class: "px-4 border-l",
              key: statusDelivery,
              title: statusDelivery.title,
              tooltipTitle: statusDelivery.tooltip,
              loading: statusDelivery.loading,
              value: statusDelivery.value,
              ableToClick: statusDelivery.ableToClick,
              color: statusDelivery.color,
              onClick: ($event: any) => (_ctx.onClickDetail(`status-${statusDelivery.type}`))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ProgressBar, {
                  value: 
              _ctx.valueProgressBar(statusDelivery.value, _ctx.totalStatusDelivery.value)
            ,
                  class: "w-full mr-2",
                  startValue: 0,
                  endValue: _ctx.totalStatusDelivery.value,
                  customColor: statusDelivery.color
                }, null, 8, ["value", "endValue", "customColor"])
              ]),
              _: 2
            }, 1032, ["title", "tooltipTitle", "loading", "value", "ableToClick", "color", "onClick"]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}